<template>
  <div class="guarantee d-flex justify-center">
    <v-img
      class="img hidden-sm-and-down"
      width="1120"
      height="88"
      contain
      :src="guaranteeBanner"
      :srcset="guaranteeBannerSrcSet"
    />
  </div>
</template>

<script>
import GuaranteeBanner from '@/assets/images/logo/guarantee.png';
import GuaranteeBanner2x from '@/assets/images/logo/guarantee@2x.png';
import GuaranteeBanner3x from '@/assets/images/logo/guarantee@3x.png';

export default {
  name: 'Guarantee',
  data: () => ({
    guaranteeBanner: GuaranteeBanner,
    guaranteeBannerSrcSet: `${GuaranteeBanner2x} 2x, ${GuaranteeBanner3x} 3x`,
  }),
};
</script>

<style lang="scss">
.guarantee {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div>
    <img
      :src="require('@/assets/images/background/about-us.png')"
      class="header__background"
    >
    <v-container class="mb-16">
      <div class="d-flex flex-column justify-center mt-8 mb-4">
        <span class="about__us__header">เกี่ยวกับเรา</span>
      </div>
      <v-divider />
      <div class="d-flex flex-column mt-6 mb-10">
        <span class="about__us__detail__header">บริษัท นรินทร์เภสัช จำกัด</span>
        <span class="about__us__detail__text mt-2">เราคือร้านขายยา อาหารเสริม เวชภัณฑ์สำอาง และอุปกรณ์ทางการแพทย์ ทั้งปลีกและส่ง ครอบคลุมสินค้ากว่า 6,000 รายการ กว่า 40 ปีกับการให้บริการลูกค้าที่หลากหลายทั้ง โรงพยาบาล โรงงานอุตสาหกรรม ร้านขายยา สถานพยาบาล และหน่วยงานราชการต่างๆทั่วประเทศ เราพัฒนา ปรับปรุง และแก้ไของค์กรอย่างต่อเนื่อง เพื่อมาตรฐานสูงสุดสำหรับลูกค้าทุกท่าน ให้ท่านได้รับความสะดวก รวดเร็ว และการดูแลอย่างดีที่สุดอยู่เสมอ รวมไปถึงเราได้คัดสรรสินค้าที่มีคุณภาพ ราคาหลากหลาย ได้รับความไว้วางใจจากลูกค้าในหลายภาคส่วนทั่วประเทศ</span>
      </div>
      <Guarantee />
    </v-container>
    <div class="d-flex justify-space-between">
      <img
        :src="require('@/assets/images/background/nrn-cr-bg-left-2.png')"
        height="200"
      >
      <img
        :src="require('@/assets/images/background/nrn-cr-bg-right-2.png')"
        height="200"
        class="mt-n16"
      >
    </div>
  </div>
</template>

<script>
import Guarantee from '@/components/Guarantee.vue';

export default {
  name: 'AboutUs',
  components: {
    Guarantee,
  }
};
</script>

<style lang="scss">
.header__background {
  width: 100%;
  height: 260px;
}

.about__us__detail__header {
  font-family: Kanit;
  font-size: 20px;
  line-height: 1.8;
  color: #242424;
}

.about__us__detail__text {
  font-family: Sarabun;
  font-size: 18px;
  line-height: 1.78;
  text-indent: 2em;
  color: #242424;
}

.about__us__header {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
}
</style>
